<template>
 <v-container id="dashboard-steps-page" class="grey lighten-5 pa-0 ma-0">
    <v-form  @submit.prevent="formSubmit">
      <base-card elevation="5" class="pa-0 ma-0" color="#f2f2f2" :loading="donorSearchLoading">
        <v-row no-gutters class="pa-0 ma-0" style="height: 550px;">
          <v-col cols="4" class="pa-0 ma-0 pa-7">
              <step-sidebar @goHome="currentPage = 1" @next="autoNext" :currentPage="currentPage" />
          </v-col>
          <v-col cols="8" class="pa-0 ma-0 pa-7 white">
              <v-card-title class="primary--text font-weight-bold d-inline-block pa-0 title-border">
                {{stepTitle}}
              </v-card-title>
              <step-one v-if="currentPage === 1" :v="$v" @fieldMismatch="handleFieldMismatch"/>
              <step-two v-if="currentPage === 2" :v="$v" nameField="true" />
          </v-col>
        </v-row>
        <step-footer :currentPage="currentPage" @goHome="currentPage = 1" @back="goBack" :showAlertDialog="showAlertDialog" :resetValidation="resetValidation"/>
      </base-card>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { email, required } from "vuelidate/lib/validators";

export default {
  metaInfo: {
    title: "Manage Contacts",
  },
  components: {
    StepSidebar: () => import("@/pages/update-contact/StepSidebar"),
    StepFooter: () => import("@/pages/update-contact/StepFooter"),
    StepOne: () => import("@/pages/update-contact/components/Step1"),
    StepTwo: () => import("@/pages/update-contact/components/Step2"),
    Modal: () => import("@/components/common/modal"),
  },
  data() {
    return {
      currentPage: 1,
      stepTitle: 'Contact Details',
      showAlertDialog: false,
    };
  },
  computed: {
    ...mapGetters(["donorSearchLists", "donorSearchLoading", "model"]),
  },
  validations: {
    model: {
        email: { email},
        first_name: { required},
        last_name: { required},
    }
  },
  created() {
    this.$store.dispatch('refreshData');
  },
  methods: {
    ...mapActions(["updateContact", "createContact"]),

    goBack () {
        if (this.currentPage !== 1) {
          this.currentPage -= 1 ;
        } else {
          this.currentPage = 1;
        }
    },
    autoNext () {
      if (this.currentPage === 1) {
          this.currentPage += 1;
      }
    },
    formSubmit() {
        this.$v.$touch();
        this.showAlertDialog = false;
        if (this.$v.$invalid && this.currentPage === 1) {
          this.showAlertDialog = false;
          return;
        } else if (this.currentPage === 2) {
          let action, message;
          this.model.screen = 'save-contact';

          if(this.model.id) {
              action = this.updateContact(this.model);
              message = 'Contact Updated Successfully';
          } else {
              action = this.createContact(this.model);
              message = 'Contact Created Successfully';
          }

          action.then(response => {
            this.$store.commit("snackbar/SHOW_MESSAGE", {
              text: message,
              color: "success",
            });
            this.$store.dispatch('refreshData');
            this.currentPage = 1;
            this.$v.model.$reset();
          });
        }
        this.currentPage += 1;
    },
    handleFieldMismatch(mismatch) {
      this.showAlertDialog = mismatch;
    },
    resetValidation() {
      this.$v.model.$reset();
      this.$store.dispatch('refreshData');
      this.$emit('reset-validation');
    },
  }
};
</script>

<style lang="scss">
.selectable {
  .v-label {
    color: white !important;
  }
}
</style>
